import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IUser } from '../interfaces/user.model';
import { StorageService } from '../services/storage.service';
import { WindowService } from '../services/window.service';
import { Router } from '@angular/router';

declare interface IUserData {
    email: string;
    firstName: string;
    id: number;
    isVerifiedEmail: boolean
    lastName: string;
    middleName: string;
    password: string;
    planName: string;
    stripeCustomerId: string;
}

export interface ILoginContext {
    username: string;
    password: string;
    reCaptchaToken: string;
}

export interface ILogin {
    id: number;
    isUserPlanExist: boolean;
    planName: string;
    token: string;
    userId: number;
    isAdmin: boolean;
    role: string;
    isVerifiedEmail: boolean;
    createdAt: number;
}

export interface IRegister {
    planPrice: number;
    token: string;
    isPlanPurchased: boolean,
    user: IUserData
}

export interface IVerifyEmail {
    message: string;
}

export interface IUpdateProfileContext {
    email: string;
    firstName: string;
    isVerifiedEmail: boolean;
    lastName: string;
    middleName: string;
    currentTeamId: string;
}

declare interface IChangePasswordContext {
    currentPassword: string;
    newPassword: string;
}

declare interface IResponseMessage {
    message: string;
}

declare interface IModifyPasswordContext {
    code: string;
    confirmPassword: string;
    email: string;
    password: string;
}
declare interface IResponseLeadForAffiliate {
    message: boolean;
}

// declare interface IVerifyEmailContext {
//   code: string;
//   email: string;
// }

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public user: IUser;
    @Output() public userEventEmitter: EventEmitter<IUser> = new EventEmitter();
    public isTokenExpired: boolean;
    public constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private windowService: WindowService,
        private router: Router,
    ) { }

    /**
     * It will get token from the local storage.
     */
    public getToken() {
        return this.storageService.getItem('accessToken');
    }

    /**
     * It will get refresh token from the local storage.
     */
    public getRefreshToken() {
        return this.storageService.getItem('refreshToken');
    }
    /**
     * It will store the data at specified key in local storage.
     * @param key : key string at which data will be stored.
     * @param data: data to be stored.
     */
    // public setLocalStorage(key: string, data: any) {
    //   localStorage.setItem(key, JSON.stringify(data));
    // }

    /**
     * It will delete key-data at specified key.
     * @param key : key string for deleting data.
     */
    // public deleteLocalStorageKey(key: string) {
    //   localStorage.removeItem(key);
    // }

    /**
     * It will return data from specified key.
     * @param key : key string for getting data.
     */
    public getLocalStorage(key: string) {
        try {
            return JSON.parse(this.storageService.getItem(key));
        } catch (error) {
            return {};
        }
    }

    /**
     * It will checked for token.
     * It will return true if token exists otherwise false.
     */
    public isAuthenticated(): Promise<boolean> {

        const token = this.getToken();
        if (!token) {
            return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }

    /**
     * It will logged user in.
     * @param data : data object
     */
    public doLogin(data): Observable<ILogin> {
        return this.http.post<ILogin>(environment.REST_URL + '/auth/sign-in', data)
            .pipe(
                retry(0),
                catchError(this.handleError),
            );
    }

    /***
     * Used for registration.
     */
    public doRegister(data): Observable<IRegister> {
        return this.http.post<IRegister>(environment.REST_URL + '/auth/sign-up', data);
    }

    /**
     * It will handle errors.
     * @param error : error information
     */
    public handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = error;
        }
        return throwError(errorMessage);
    }

    /**
     * It will get the current user data.
     */
    public async getUser(userId?: string): Promise<IUser> {
        userId = this.storageService.getItem('viewUserId');
        let path = '/users/me';
        let requestData = {};
        if (userId) {
            path = '/admin/user/me';
            requestData = { params: { userId } };
        }
        const user = this.http.get(environment.REST_URL + path, requestData)
            .pipe(
                retry(1),
                catchError(this.handleError),
            ).toPromise()
            .then((user: IUser) => {
                this.user = user;
                this.userEventEmitter.emit(user);
                return user;
            });
        return user;
    }

    /**
     * It will remove user data from local storage.
     */
    public doLogout() {
        return this.http.get(environment.REST_URL + '/auth/logout', {});
    }

    /**
     * It is used for updating current user data as per the new data.
     * @param user : user data
     */
    public doUpdateProfile(user: Partial<IUpdateProfileContext>): Observable<IUserData> {
        const userId = this.storageService.getItem('viewUserId');
        if (userId) {
            return this.http.put<IUserData>(environment.REST_URL + '/users/profile', user, { params: { userId } });
        }
        return this.http.put<IUserData>(environment.REST_URL + '/users/profile', user);
    }

    /**
     * It is used for updating password.
     */
    public doChangePassword(data: IChangePasswordContext): Observable<IResponseMessage> {
        return this.http.put<IResponseMessage>(environment.REST_URL + '/users/changePassword', data);
    }

    /**
     * It is used for generating token.
     */
    public generateToken(userId: string, teamId: string): Promise<any> {
        return this.http.post(environment.REST_URL.concat('/users/generateToken'), { userId, teamId }).toPromise();
    }

    /**
     * It will remove token of current user.
     */
    public removeToken(id) {
        return this.http.put(environment.REST_URL + `/users/updateToken/${id}`, {});
    }

    /**
     * It is used for requesting new password.
     */
    public requestPassword(data): Observable<IResponseMessage> {
        return this.http.post<IResponseMessage>(environment.REST_URL + '/users/requestPassword', data);
    }

    /**
     * It is used for modifying current user password.
     */
    public modifyPassword(data: IModifyPasswordContext): Observable<IUserData> {
        return this.http.post<IUserData>(environment.REST_URL + '/users/modifyPassword', data);
    }

    /**
     * It is used for verifying current user email.
     */
    public verifyEmail(data): Observable<IVerifyEmail> {
        return this.http.post<IVerifyEmail>(environment.REST_URL + '/users/verifyEmail', data);
    }

    // public getMessages = () => {
    //   return Observable.create((observer) => {
    //     this.socket.on('messageToClient', (message) => {
    //       console.log('MESSAGE', message);
    //       observer.next(message);
    //     });
    //   });
    // }

    public resendEmailVerification(): Observable<IResponseMessage> {
        return this.http.post<IResponseMessage>(environment.REST_URL + '/users/resendVerificationEmail', {});
    }

    /**
    * It will change user preference for affiliate and check is user already visited thank you page or not.
    */
    public setUserVisited(): Observable<IResponseLeadForAffiliate> {
        return this.http.put<IResponseLeadForAffiliate>(environment.REST_URL + '/users/share-sale/lead/', {});
    }

    /**
    * It will redirect to google authentication page.
    */
    public redirectToGoogleAuth(userId: string): Window {
        return this.windowService.nativeWindow.open(environment.REST_URL + `/google?userId=${userId}`, '_self');
    }

    /**
    * It will check user is admin or not.
    */
    public get isAdmin(): boolean {
        return this.user && this.user.isAdmin ? this.user.isAdmin : false;
    }
    /**
    * It will check user is editor or not.
    */
    public get isEditor(): boolean {
        return this.user && this.user.role === 'EDITOR' ? true : false;
    }
    /**
    * To Verify Captcha
    */
    public verifyCaptcha(reCaptchaToken: string): Promise<any> {
        return this.http.post(environment.REST_URL + `/users/verifyCaptcha?reCaptchaToken=${reCaptchaToken}`, {}).toPromise();
    }

    /**
     * It is used for verifying invitation and accept or decline the invitation.
     */
    public verifyInvitation(data): Promise<any> {
        return this.http.post<any>(environment.REST_URL + '/teams/verify-invitation', data).toPromise();
    }
    /**
     * Verify invitation code
     */
    public verifyInvitationCode(code): Promise<any> {
        return this.http.post<any>(environment.REST_URL + '/teams/verify-code', { code }).toPromise();
    }

    /**
    * Get two new token and user data for stay login
    */
    public refreshToken(): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.getRefreshToken()}`
        });
        return this.http.get(environment.REST_URL.concat('/users/refresh'), { headers }).pipe(
            map((response: any) => {
                const newToken = response.token;
                this.setToken(newToken);
                this.setRefreshToken(response.newRefreshToken);
                return response;
            })
        );
    }

    public setToken(token: string): void {
        return this.storageService.setItem('token', token);
    }

    public setRefreshToken(token: string): void {
        return this.storageService.setItem('refresh-token', token);
    }

    /**
     * Create log for user
     * @param userId
     * @param meta
     * @param event
     * @returns
     */
    public createLog(userId: string, meta: any, event: number): Promise<string> {
        return this.http.post<any>(environment.REST_URL + '/users/create-log', { userId, event, meta }).toPromise();
    }

    /**
    * Save extra info of user
    */
    public extraInfo(data): Promise<string> {
        return this.http.post<any>(environment.REST_URL + '/users/extra-info', data).toPromise();
    }
}
