/* eslint-disable no-restricted-globals */
import { Injectable } from '@angular/core';

function _window(): any {
	// return the global native browser window object
	try {
		return window;
	} catch (error) {
		console.error('error ', error);
	}
}

@Injectable()
export class WindowService {
	public get nativeWindow(): any {
		return _window();
	}
}